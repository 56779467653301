import { AxiosError } from "axios";
import { toast } from "react-toastify";
import i18next from "../../i18/i18n";

/**
 * Handle logging service errors
 * @param {AxiosError} err Error from the request attempt
 * @param {(error: string) => void} setErrorHandler set Error string
 */

export const handleRequestError = (err: AxiosError, setErrorHandler: (error: string) => void) => {
  // * catch the error
  if (err && err.response) {
    setErrorHandler(`Request failed with response code: ${err.response.status}`);
    // error log any response data rather than into the toast. This will be viewable upon investigating
    console.error(JSON.stringify(err.response.data));
  } else if ("request" in err) {
    setErrorHandler(`Request made but no response received from server`);
    console.error(err.request);
  } else {
    setErrorHandler(`Error: ${err.message}`);
  }

  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  toast.error(`${i18next.t("errors.wentWrong")}: ${err.message}`);
};
