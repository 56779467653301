import * as buffer from "buffer";
import { Auth0Provider } from "@auth0/auth0-react";
import CarbonProvider from "carbon-react/lib/components/carbon-provider";
import "carbon-react/lib/style/fonts.css";
import GlobalStyle from "carbon-react/lib/style/global-style";
import sageTheme from "carbon-react/lib/style/themes/sage";
import { AuthStoreProvider } from "providers/AuthStoreProvider/AuthStoreProvider";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Routes } from "routes/routes";
import { storeSessionSfAIds } from "utilities/SfAUtils/getSfAIds";
import App from "./App";
import "./styles/index.scss";
import "./i18/i18n";

// Defines the global buffer to use for enconding and decoding.
window.Buffer = buffer.Buffer;

const CLIENT_ID = process.env["REACT_APP_CLIENT_ID"] ?? "";
const CLIENT_DOMAIN = process.env["REACT_APP_CLIENT_DOMAIN"] ?? "";
const AUDIENCE = process.env["REACT_APP_AUDIENCE"] ?? "";

const REDIRECT_URL = `${process.env["REACT_APP_DOMAIN"] ?? ""}${Routes.SageIdCallback}`;

/**
 * * Define the global expected window values for Typescript (mostly for WP globals)
 */
declare global {
  interface Window {
    featureFlags?: Array<string>;
    amlPermissions?: string;
    ajaxurl?: string;
    jQuery?: unknown;
  }
}

// * presumtively store SfA ids on load if they are present
storeSessionSfAIds();

ReactDOM.render(
  <React.StrictMode>
    <CarbonProvider theme={sageTheme}>
      <GlobalStyle />
      <ToastContainer
        limit={1}
        closeOnClick={false}
        autoClose={6000}
        position={"top-center"}
        closeButton={false}
        draggable={false}
      />
      <AuthStoreProvider>
        <BrowserRouter>
          <Auth0Provider
            clientId={CLIENT_ID}
            domain={CLIENT_DOMAIN}
            redirectUri={REDIRECT_URL}
            audience={AUDIENCE}
            scope="openid profile email user:full offline_access"
          >
            <App />
          </Auth0Provider>
        </BrowserRouter>
      </AuthStoreProvider>
    </CarbonProvider>
  </React.StrictMode>,
  document.getElementById("root") as HTMLElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
