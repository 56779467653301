import { LoadingField } from "components/UI/LoadingField";
import { Suspense } from "react";
import Router from "routes/Router";

const App = () => (
  <Suspense fallback={<LoadingField />}>
    <Router />
  </Suspense>
);
export default App;
