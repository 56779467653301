/**
 * * Interface to handle external requests. Currently uses Axios but this can be swapped out
 * * without affecting the app if required.
 * * Avoid using directly for services. Instead, create a service that extends the useRequestServiceImplementation
 * * and set up only the methods you requires to hook into this service. (See useRequestLogin as an example)
 */

import { AxiosError, AxiosRequestConfig } from "axios";
import {
  useRestDeleteAxios,
  useRestGetAxios,
  useRestPatchAxios,
  useRestPostAxios,
} from "fetch/axiosHooks";
import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { handleRequestError } from "services/serviceUtilities/handleRequestError";
import { isLocalEnv } from "utilities/utils";
import { useRequestServiceInterface } from "./useRequestServiceInterface";
import { handleResponse } from "./useRequestServiceUtilities";

export const useRequestService = <T>(): useRequestServiceInterface<T> => {
  /**
   * * useRest[method]Axios hooks also expose { data, error, response }, manualCancel
   */

  /**
   * GET Request Hook
   */
  const [{ loading: getLoading }, getExecute] = useRestGetAxios<T>(
    {},
    {
      manual: true,
      useCache: false,
      ssr: false,
    }
  );

  /**
   * POST Request Hook
   */
  const [{ loading: postLoading }, postExecute] = useRestPostAxios<T>(
    {},
    {
      manual: true,
      useCache: false,
      ssr: false,
    }
  );

  /**
   * PATCH Request Hook
   */
  const [{ loading: patchLoading }, patchExecute] = useRestPatchAxios<T>(
    {},
    {
      manual: true,
      useCache: false,
      ssr: false,
    }
  );

  /**
   * DELETE Request Hook
   */
  const [{ loading: deleteLoading }, deleteExecute] = useRestDeleteAxios<T>(
    {},
    {
      manual: true,
      useCache: false,
      ssr: false,
    }
  );

  /**
   * * Track most recent error that occurred
   */
  const [RequestError, setRequestError] = useState<string | null>(null);

  /**
   * * Get auth hook to clear access token on not-auth error
   */
  const { clearAccessTokens } = useAuth();

  /**
   * * Reset the service
   */
  const reset = useCallback(() => {
    setRequestError(null);
  }, []);

  /**
   * * Handle response error
   */
  const handleResponseError = useCallback(
    (err: AxiosError) => {
      if (err && err.response) {
        // * Check error response code
        if (err.response.status === 401 || err.response.status === 403) {
          // * Unauthorised responses, so clear the access tokens and log the user out
          // * Check clearAccessToken exists (within AuthProvider) before calling
          typeof clearAccessTokens === "function" && clearAccessTokens();
        }
      }
      // prevents strict mode local errors due to canceled requests.
      if (isLocalEnv && err.code === "ERR_CANCELED") {
        return;
      }
      // * Handle error
      handleRequestError(err, setRequestError);
    },
    [clearAccessTokens]
  );

  // * ----- API ENDPOINTS -----* //

  /**
   * * send get request
   */
  const sendGetRequest = useCallback(
    (config: AxiosRequestConfig, callback: (data: T) => void) => {
      // * Don't run if service is already running
      if (getLoading) return;

      // * Clear any current errors
      setRequestError(null);

      getExecute(config)
        .then((response) => handleResponse(response, callback, setRequestError))
        .catch(handleResponseError);
    },
    [getExecute, getLoading, handleResponseError]
  );

  /**
   * * Send post request
   */
  const sendPostRequest = useCallback(
    (config: AxiosRequestConfig, callback: (data: T) => void) => {
      // * Don't run if service is already running or an error has occurred
      if (postLoading) return;

      // * Clear any previous errors
      setRequestError(null);

      postExecute(config)
        .then((response) => handleResponse(response, callback, setRequestError))
        .catch(handleResponseError);
    },
    [handleResponseError, postExecute, postLoading]
  );

  /**
   * * Send patch request
   */
  const sendPatchRequest = useCallback(
    (config: AxiosRequestConfig, onResponse: (data: T) => void) => {
      // * Don't run if service is already running or an error has occurred
      if (patchLoading) return;

      // * Clear any current errors
      setRequestError(null);

      patchExecute(config)
        .then((response) => handleResponse(response, onResponse, setRequestError))
        .catch(handleResponseError);
    },
    [handleResponseError, patchExecute, patchLoading]
  );

  /**
   * * Send delete request
   */
  const sendDeleteRequest = useCallback(
    (config: AxiosRequestConfig, onResponse: (data: T) => void) => {
      // * Don't run if service is already running or an error has occurred
      if (deleteLoading) return;

      // * Clear any current errors
      setRequestError(null);

      deleteExecute(config)
        .then((response) => handleResponse(response, onResponse, setRequestError))
        .catch(handleResponseError);
    },
    [deleteExecute, deleteLoading, handleResponseError]
  );

  /**
   * * Clear error
   */
  const clearError = useCallback(() => setRequestError(null), []);

  return {
    // * Service Loading State
    IsLoading: getLoading || postLoading || patchLoading || deleteLoading,
    // * Service Error State
    RequestError,
    // * Reset service
    reset,
    // * send get request
    sendGetRequest,
    // * Send post request
    sendPostRequest,
    // * Send patch request
    sendPatchRequest,
    // * Send delete request
    sendDeleteRequest,
    // * Clear error
    clearError,
  };
};
