import { PropsWithChildren, useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { useRequestLogin } from "services/useRequestLogin/useRequestLogin";
import {
  LoginResponseInterface,
  postLegacyLoginConfig,
} from "services/useRequestLogin/useRequestLoginInterface";
import { InitialAuthState } from "./AuthState";
import * as AUTH_STORE_ACTIONS from "./AuthStoreActions";
import AuthStoreContext, { AuthContextInterface } from "./AuthStoreContext";
import { AuthStoreReducer } from "./AuthStoreReducer";

const TWO_FACTOR_AUTH_RESPONSE = "tfa";

/**
 * * Token store provider
 */
const { Provider } = AuthStoreContext;

/**
 * * Create Provider component to wrap around content
 */
export const AuthStoreProvider = (props: PropsWithChildren<unknown>) => {
  const [needsTfa, setNeedsTfa] = useState(false);
  const [skipDisclaimer, setSkipDisclaimer] = useState(false);

  /**
   * * Prepare the Reducer to store the auth data
   */
  const [authState, authStateDispatch] = useReducer(AuthStoreReducer, InitialAuthState);

  /**
   * * Get service hook
   */
  const { Tokens, RequestError, IsLoading, postLegacyLogin } = useRequestLogin();

  const setAccessToken = useCallback(({ token }: LoginResponseInterface) => {
    // * Store locally in auth provider
    authStateDispatch({
      type: AUTH_STORE_ACTIONS.SET_TEMP_ACCESS_TOKEN,
      payload: { token },
    });
  }, []);

  /**
   * * Get the current Auth token
   */
  const getAccessToken = useCallback(() => authState.token, [authState.token]);

  /**
   * * Clear the Auth token
   */
  const clearAccessTokens = useCallback(() => {
    authStateDispatch({
      type: AUTH_STORE_ACTIONS.CLEAR_ACCESS_TOKENS,
    });
  }, []);

  /**
   * * Check if is authenticated
   */
  const isAuthenticated = useCallback(
    () => authState.is_authenticated,
    [authState.is_authenticated]
  );

  /**
   * * Request Auth Token
   */
  const requestLogin = useCallback(
    (data: postLegacyLoginConfig) => postLegacyLogin({ ...data }),
    [postLegacyLogin]
  );

  /**
   * * If Data is changed (and has content) then reset service
   */
  useEffect(() => {
    if (Tokens) {
      if (Tokens.token === TWO_FACTOR_AUTH_RESPONSE) {
        return setNeedsTfa(true);
      }
      setNeedsTfa(false);
      setSkipDisclaimer(Tokens.skip_prompt);
      setAccessToken(Tokens);
    }
  }, [Tokens, setAccessToken]);

  /**
   * * Prepare memoised context value
   */
  const contextValue: AuthContextInterface = useMemo(() => {
    return {
      getAccessToken,
      clearAccessTokens,
      isAuthenticated,
      requestLogin,
      IsLoading,
      Error: RequestError,
      needsTfa,
      skipDisclaimer,
    };
  }, [
    getAccessToken,
    clearAccessTokens,
    isAuthenticated,
    requestLogin,
    IsLoading,
    RequestError,
    needsTfa,
    skipDisclaimer,
  ]);

  return <Provider value={contextValue}>{props.children}</Provider>;
};
