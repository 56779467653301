import axios, { AxiosRequestConfig } from "axios";
import { makeUseAxios } from "axios-hooks";
import { isLocalEnv } from "utilities/utils";

/**
 * Shared base axios config for REST api methods (non-WP)
 * withCredentials ensures that we can test on diferent ports locally with the backend
 */
const sharedBaseAxiosRestConfig: AxiosRequestConfig = {
  baseURL: process.env["REACT_APP_API"] ?? "",
  headers: { "Content-Type": "application/json" },
  withCredentials: isLocalEnv ? true : undefined,
};

/**
 * useAxios hook for the REST API GET Method
 */
export const useRestGetAxios = makeUseAxios({
  axios: axios.create({
    ...sharedBaseAxiosRestConfig,
    method: "get",
  }),
});

/**
 * useAxios hook for the REST API POST Method
 */
export const useRestPostAxios = makeUseAxios({
  axios: axios.create({
    ...sharedBaseAxiosRestConfig,
    method: "post",
  }),
});

/**
 * useAxios hook for the REST API PATCH Method
 */
export const useRestPatchAxios = makeUseAxios({
  axios: axios.create({
    ...sharedBaseAxiosRestConfig,
    method: "patch",
  }),
});

/**
 * useAxios hook for the REST API DELETE Method
 */
export const useRestDeleteAxios = makeUseAxios({
  axios: axios.create({
    ...sharedBaseAxiosRestConfig,
    method: "delete",
  }),
});

/**
 * useAxios hook for WordPress API
 */
export const useWPAxios = makeUseAxios({
  axios: axios.create({ baseURL: process.env["REACT_APP_WP_AJAX_URL"] ?? "" }),
});
