import { AnimatePresence, HTMLMotionProps, MotionProps, motion } from "framer-motion";
import { PropsWithChildren } from "react";

const initialProp = { y: -16, opacity: 0, minHeight: "auto" };
const animateProp = { y: 0, opacity: 1 };
const defaultTransition = {
  delay: 0.25,
  duration: 0.2,
};

export const PageTransition = ({
  children,
  transition,
  ...rest
}: PropsWithChildren<MotionProps & HTMLMotionProps<"div">>) => {
  return (
    <motion.div
      {...rest}
      initial={initialProp}
      animate={animateProp}
      transition={transition ? transition : defaultTransition}
    >
      <AnimatePresence>{children}</AnimatePresence>
    </motion.div>
  );
};
