export interface URLTemplateReplacementsMapInterface {
  [replacementKey: string]: string;
}

export interface URLParamsInterface {
  [paramKey: string]: string;
}

/**
 * * Replace items within a template URL where items to be replaced are symbolised as a `:key` and replacements is an object of matching `key`: value pairs, where value is the replacement.
 * @param {string} urLTemplate A template URL
 * @param {URLTemplateReplacementsMapInterface} replacements An object mapping keys to replacements
 * @param {URLParamsInterface} params An object mapping param keys to values
 * @returns {string} The template URL with items replaced
 */
export const generateCompleteURL = (
  urLTemplate: string,
  replacements: URLTemplateReplacementsMapInterface,
  params: URLParamsInterface
): string => {
  let URL = urLTemplate;

  // * Replace any keyed items in the URL template
  Object.keys(replacements).forEach((replacementKey: string) => {
    URL = URL.replace(new RegExp(`:${replacementKey}`), replacements[replacementKey] as string);
  });

  // * Generate the param string and add to the URL
  let paramString = "";

  Object.keys(params).forEach((paramKey: string) => {
    if (paramString.length === 0) {
      paramString = paramString.concat("?");
    } else {
      paramString = paramString.concat("&");
    }

    paramString = paramString.concat(`${paramKey}=${params[paramKey] as string}`);
  });

  return URL.concat(paramString);
};
