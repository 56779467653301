import {
  PRESELECT_TENANT_COOKIE,
  TENANT_COOKIE,
} from "components/LoginUser/providers/LoginUserProvider";
import { produce } from "immer";
import { Cookies } from "utilities/Cookies/Cookies";
import {
  deleteSessionStorageItem,
  setSessionStorageItem,
} from "utilities/SessionStorage/SessionStorage";
import { SS_TEMP_ACCESS_TOKEN } from "utilities/SessionStorage/storageKeys";
import { AuthStateInterface, LOGIN_WITH_SAGE_NAME } from "./AuthState";
import * as AUTH_STORE_ACTIONS from "./AuthStoreActions";
import { AuthActionInterface } from "./Interfaces";

/**
 * * Local authorised logic check
 * * Ensure a valid token has actually been passed - is truthy, is a string, and has a length > 0
 * * admittedly overzealous when "" is falsy and would fail at the first check
 * @param {string} token
 * @returns
 */
const checkIfAuthorised = (token: string) => token && typeof token === "string" && token.length > 0;

/**
 * * Auth store reducer
 */
export const AuthStoreReducer = (
  oldState: AuthStateInterface,
  { type, payload }: AuthActionInterface
): AuthStateInterface => {
  switch (type) {
    /**
     * * Set the Auth Token
     */
    case AUTH_STORE_ACTIONS.SET_TEMP_ACCESS_TOKEN:
      return produce(oldState, (draft) => {
        if (payload && payload.token) {
          const { token } = payload;

          // * Check that the provided token is a valid auth token
          if (checkIfAuthorised(token)) {
            draft.token = token;
            draft.is_authenticated = true;

            // * Set session storage for access token
            setSessionStorageItem(SS_TEMP_ACCESS_TOKEN, token);
          } else {
            // * Not valid, so clear any existing auth

            // * clear cookies
            deleteSessionStorageItem(SS_TEMP_ACCESS_TOKEN);

            // * return cleared data
            draft.is_authenticated = false;
            draft.token = null;
          }
        } else {
          console.warn(`[AuthStoreReducer:SET_ACCESS_TOKEN] Incorrect payload provided`);
        }
      });
    /**
     * * Clear the Auth Token
     */
    case AUTH_STORE_ACTIONS.CLEAR_ACCESS_TOKENS: {
      // * clear token cookie
      Cookies.clearCookie(TENANT_COOKIE);
      Cookies.clearCookie(PRESELECT_TENANT_COOKIE);
      Cookies.clearCookie(LOGIN_WITH_SAGE_NAME);
      deleteSessionStorageItem(SS_TEMP_ACCESS_TOKEN);

      // * return cleared values
      return {
        is_authenticated: false,
        expires_at: null,
        token: null,
      };
    }

    default:
      console.warn(`[AuthStoreReducer] Unknown type ${type} provided`);
      return oldState;
  }
};
