import i18next, { use } from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import { enTranslations } from "./translations/en/index";
import { enCATranslations } from "./translations/en-CA/index";
import { frTranslations } from "./translations/fr/index";
import { frCATranslations } from "./translations/fr-CA/index";
import { Translations } from "./translations/translationInterface";

export enum AcceptedLanguages {
  EN = "en",
  FR = "fr",
  EN_CA = "en-CA",
  FR_CA = "fr-CA",
}

// Helper function to get locale of user.
const getCurrentLocale = () => {
  const locale = navigator.language;
  switch (true) {
    case locale.includes(AcceptedLanguages.EN):
      return AcceptedLanguages.EN;
    case locale.includes(AcceptedLanguages.FR):
      return AcceptedLanguages.FR;
    case locale.includes(AcceptedLanguages.EN_CA):
      return AcceptedLanguages.EN_CA;
    case locale.includes(AcceptedLanguages.FR_CA):
      return AcceptedLanguages.FR_CA;
    default:
      return AcceptedLanguages.EN;
  }
};

// Wrapper hook which uses the correct locale when called.
export const useTranslate = () => {
  const { t } = useTranslation();

  return {
    t: t as (val: AvailablePaths<Translations>, opts?: object) => string,
  };
};

export const defaultNS = getCurrentLocale();

// Initializes the plugin

use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === "development",
    fallbackLng: AcceptedLanguages.EN,
    lng: getCurrentLocale(),
    resources: {
      en: { translation: enTranslations },
      fr: { translation: frTranslations },
      "en-CA": { translation: enCATranslations },
      "fr-CA": { translation: frCATranslations },
    },
    interpolation: { escapeValue: false },
  })
  .catch((err) => console.error("Init translations error: ", err));

export default i18next;

type AvailablePaths<T> = T extends object
  ? {
      [K in keyof T]: `${K & string}${"" extends AvailablePaths<T[K]>
        ? ""
        : `.${AvailablePaths<T[K]>}`}`;
    }[keyof T]
  : "";
