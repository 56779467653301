/**
 * * Define the Auth context for the App
 */

import { createContext } from "react";
import { postLegacyLoginConfig } from "services/useRequestLogin/useRequestLoginInterface";

/**
 * * Interface for the Auth context
 */
export interface AuthContextInterface {
  /**
   * * Get the current Auth token
   */
  readonly getAccessToken: () => null | string;

  /**
   * * Clear the Auth token
   */
  readonly clearAccessTokens: () => void;

  /**
   * * Check if is authenticated
   */
  readonly isAuthenticated: () => boolean;

  /**
   * * Request Auth Token
   */
  readonly requestLogin: (data: postLegacyLoginConfig) => void;

  /**
   * * Is Requesting Auth Token
   */
  readonly IsLoading: boolean;

  /**
   * * Request Error
   */
  readonly Error: null | string;

  /**
   * * Two factor authentication code
   */
  readonly needsTfa: boolean;

  /**
   * * Flag that backend sends to skip the sageId disclaimer
   */
  readonly skipDisclaimer: boolean;
}

/**
 * * Create a context for the Auth Store Provider
 */
const AuthStoreContext = createContext<AuthContextInterface>({} as AuthContextInterface);

export default AuthStoreContext;
