import Box from "carbon-react/lib/components/box";
import Image from "carbon-react/lib/components/image";
import useLoginUser from "components/LoginUser/providers/useLoginUser";
import gopLogo from "media/logo-go-proposal.svg";
import osLogo from "media/logo-over-suit.png";
import SageLogoSVG from "media/sage-logo.svg";
import { CURRENT_APP_TITLE } from "utilities/utils";

interface IProps {
  readonly useSageLogo?: boolean;
}

const Logo = ({ useSageLogo }: IProps) => {
  const { selectedTenant } = useLoginUser();

  if (useSageLogo) {
    return <Image src={SageLogoSVG} alt="Sage Logo" maxHeight={64} maxWidth={114} />;
  }

  const logo = selectedTenant?.logo_url ?? (CURRENT_APP_TITLE === "OverSuite" ? osLogo : gopLogo);

  return <Image src={logo} alt="App Logo" maxHeight={71} maxWidth={300} />;
};

export const AppLogo = (props: IProps) => (
  <Box mt={4} display="flex" width={1} justifyContent="center" mb={3}>
    <Logo {...props} />
  </Box>
);
