import { useAuth0 } from "@auth0/auth0-react";
import { GET_TENANTS } from "fetch/endpoints";
import { useResource } from "hooks/useResource/useResource";
import { useTranslate } from "i18/i18n";
import { Identifiable } from "interfaces/Identifiable";
import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useRequestService } from "services/useRequestService/useRequestService";
import { Cookies } from "utilities/Cookies/Cookies";
import { generateAuth0LoginHint } from "utilities/generateAuth0LoginHint/generateAuth0LoginHint";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { getSessionSfAIds } from "utilities/SfAUtils/getSfAIds";
import LoginUserContext, { LoginUserContextInterface } from "./LoginUserContext";
import { TenantEmail } from "../IdentifyUser/IdentifyUserForm";

export interface User extends Identifiable {
  readonly email: string;
  readonly sage_id: string | null;
  readonly data: ReadonlyArray<Tenant>;
}

export interface Tenant extends Identifiable {
  readonly name: string;
  readonly domain: string;
  readonly path: string;
  readonly brand_colour: string | null;
  readonly logo_url: string | null;
}

export const USER_EMAIL_URL_PARAM = "userEmail";
export const SELECTED_TENANT_URL_PARAM = "tenant";

export const TENANT_COOKIE = "tenant";
export const PRESELECT_TENANT_COOKIE = "preselect-tenant";

const AUDIENCE = process.env["REACT_APP_AUDIENCE"] ?? "";

const { Provider } = LoginUserContext;

export const LoginUserProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { t } = useTranslate();
  const { resource, updateResource, createResource } = useResource<User>();
  const [selectedTenant, setSelectedTenant] = useState<Tenant>();
  const [hasSageID, setHasSageID] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const [searchParams, setSearchParams] = useSearchParams();
  const { sendGetRequest, IsLoading } = useRequestService();

  const [userEmail, setUserEmail] = useState(searchParams.get(USER_EMAIL_URL_PARAM) ?? undefined);
  const [preSelectedTenantPath] = useState(() => {
    // * check for tenant in params
    const tenantPathFromParams = searchParams.get(SELECTED_TENANT_URL_PARAM);
    // * if it's there
    if (tenantPathFromParams) {
      const decodedName = decodeURIComponent(tenantPathFromParams);
      // * hold in session cookies
      Cookies.setSessionCookie(PRESELECT_TENANT_COOKIE, decodedName);
      // * return name
      return decodedName;
    }
    const tenantNameFromCookie = Cookies.getCookie(PRESELECT_TENANT_COOKIE);
    if (tenantNameFromCookie) return tenantNameFromCookie;
    return undefined;
  });
  const [preSelectedTenantId] = useState(() => {
    // * for SfA users that have multiple tenants, preselect the tenant using tid from session storage
    const ids = getSessionSfAIds();
    if (!ids) return undefined;
    const { tid } = ids;
    return tid;
  });

  // SageId docs require the login_hint to be a string of a json object encoded in base64
  const generateLoginHint = useCallback(() => generateAuth0LoginHint(userEmail), [userEmail]);

  const updateCurrentUser = useCallback(
    (resp: User, sageIdRedirect = false, email?: TenantEmail) => {
      const { data, sage_id } = resp;

      // Getting tenants from sageId redirect call with invalid email.
      if (!email && data.length === 0) {
        toast.error(t("errors.tenantNotFoundSage"));
        return;
      }
      if (sage_id) {
        setHasSageID(true);
      }
      if (data.length === 1 && data[0]) {
        if (!sageIdRedirect) Cookies.setSessionCookie(TENANT_COOKIE, data[0].path);
        setSelectedTenant(data[0]);
      }
      if (preSelectedTenantPath) {
        const preselectedTenant = data.find(
          (te) => te.path.trim().toLowerCase() === preSelectedTenantPath.trim().toLowerCase()
        );
        if (preselectedTenant) {
          if (!sageIdRedirect) Cookies.setSessionCookie(TENANT_COOKIE, preselectedTenant.path);
          setSelectedTenant(preselectedTenant);
        }
      }
      if (preSelectedTenantId) {
        const preselectedTenant = data.find((te) => te.id === preSelectedTenantId);
        if (preselectedTenant) {
          // * we should definitely using sage here
          // if (!sageIdRedirect) Cookies.setSessionCookie(TENANT_COOKIE, preselectedTenant.path);
          setSelectedTenant(preselectedTenant);
        }
      }
      return updateResource(resp);
    },
    [preSelectedTenantId, preSelectedTenantPath, updateResource, t]
  );

  const getTenants = useCallback(
    (data?: TenantEmail, sageID?: string) => {
      if (data) {
        setSearchParams({ ...searchParams, userEmail: data.email });
        setUserEmail(data.email);
      }

      const params = { email: data?.email };
      const url = generateCompleteURL(GET_TENANTS, {}, { timestamp: Date.now().toString() });
      const cb = (response: unknown) => updateCurrentUser(response as User, !!sageID, data);

      if (sageID) {
        getAccessTokenSilently({
          audience: AUDIENCE,
          scope: "openid profile email user:full offline_access",
        })
          .then((accessToken) => {
            sendGetRequest(
              {
                url,
                params,
                data: data,
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              },
              cb
            );
          })
          .catch((err: Error) => {
            toast.error(`${t("errors.wentWrong")}: ${err.message}`);
            console.log("error", err);
          });
      } else {
        sendGetRequest(
          {
            url,
            params,
            data: data,
          },
          cb
        );
      }
    },
    [setSearchParams, searchParams, updateCurrentUser, getAccessTokenSilently, sendGetRequest, t]
  );

  const selectTenant = useCallback((tenant: Tenant) => {
    Cookies.setSessionCookie(TENANT_COOKIE, tenant.path);
    setSelectedTenant(tenant);
  }, []);

  const clearTenants = useCallback(() => {
    createResource([]);
    setSelectedTenant(undefined);
    Cookies.clearCookie(TENANT_COOKIE);
    Cookies.clearCookie(PRESELECT_TENANT_COOKIE);
  }, [createResource]);

  const contextValue: LoginUserContextInterface = useMemo(() => {
    return {
      hasSageID,
      userEmail,
      selectedTenant,
      tenants: resource[0]?.data,
      isLoading: IsLoading,
      selectTenant,
      getTenants,
      generateLoginHint,
      clearTenants,
    };
  }, [
    hasSageID,
    userEmail,
    selectedTenant,
    resource,
    IsLoading,
    selectTenant,
    getTenants,
    generateLoginHint,
    clearTenants,
  ]);

  return <Provider value={contextValue}>{children}</Provider>;
};
