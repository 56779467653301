// * All endpoints used in the app held here
// * This is imported by test-utils which then sets a test
// * handler to each, preventing any external calls during test runs

/**
 * * Current env wp ajax url endpoint (by default overridden by window.ajaxurl if that exists)
 */
export const ENV_WP_AJAX_URL = process.env["REACT_APP_WP_AJAX_URL"] ?? "";

/**
 * * Example
 */
export const INDEX = `get`;
export const SHOW = `get/:id`;
export const POST = `post`;
export const PATCH = `patch/:id`;
export const DELETE = `delete/:id`;

export const WP_PATH = `/wp-json/goproposal/v2`;

export const GET_TENANTS = `${WP_PATH}/tenants`;

export const Endpoints = {
  login: {
    sageId: `/:tenant${WP_PATH}/login`,
    legacy: `/:tenant${WP_PATH}/login-legacy`,
  },
  link: {
    post: `/:tenant${WP_PATH}/users/connect-sage`,
  },
};

export const POST_REQUEST_RESET_PASSWORD = `/:tenant${WP_PATH}/users/recovery/request`;
export const POST_CHECK_RESET_PASSWORD = `/:tenant${WP_PATH}/users/recovery/check`;
export const POST_EXCHANGE_RESET_PASSWORD = `/:tenant${WP_PATH}/users/recovery/exchange`;

export const POST_CHECK_INVITE = `/:tenant${WP_PATH}/users/invite/check`;
export const POST_EXCHANGE_INVITE = `/:tenant${WP_PATH}/users/invite/exchange`;
