/**
 * Apply further encoding of reserved characters not covered by encodeURIComponent. Additional option formEncodeSpaces will replace %20 with + if required for application/x-www-form-urlencoded.
   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
   To be more stringent in adhering to RFC 3986 (which reserves !, ', (, ), and *), even though these characters have no formalized URI delimiting uses, the following can be safely used
 * @param {string} URL A URL to encode
 * @param {boolean} formEncodeSpaces Flag whether to replace space characters with +
 * @returns {string} An encoded URL
 */
export const improvedEncodeURIComponent = (
  URL: string | number,
  formEncodeSpaces?: boolean
): string => {
  let encodedURI: string = encodeURIComponent(URL).replace(
    /[!'()*]/g,
    (c) => "%" + c.charCodeAt(0).toString(16)
  );

  if (formEncodeSpaces) {
    encodedURI = encodedURI.replace(/%20/g, "+");
  }

  return encodedURI;
};
