import { createContext } from "react";
import { Tenant } from "./LoginUserProvider";
import { TenantEmail } from "../IdentifyUser/IdentifyUserForm";

export interface LoginUserContextInterface {
  readonly isLoading: boolean;
  readonly userEmail: string | undefined;
  readonly hasSageID: boolean;
  readonly tenants: ReadonlyArray<Tenant> | undefined;
  readonly selectedTenant: Tenant | undefined;
  readonly selectTenant: (tenant: Tenant) => void;
  readonly getTenants: (data?: TenantEmail, sageId?: string) => void;
  readonly generateLoginHint: () => string;
  readonly clearTenants: () => void;
}

const LoginUserContext = createContext<LoginUserContextInterface>({} as LoginUserContextInterface);

export default LoginUserContext;
