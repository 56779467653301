import { TENANT_COOKIE } from "components/LoginUser/providers/LoginUserProvider";
import { Endpoints } from "fetch/endpoints";
import { Redirects } from "fetch/redirects";
import { useTranslate } from "i18/i18n";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useRequestService } from "services/useRequestService/useRequestService";
import { Cookies } from "utilities/Cookies/Cookies";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { deleteSessionStorageItem } from "utilities/SessionStorage/SessionStorage";
import { SS_TEMP_ACCESS_TOKEN } from "utilities/SessionStorage/storageKeys";
import { getSessionSfAIds } from "utilities/SfAUtils/getSfAIds";
import {
  LoginResponseInterface,
  postLegacyLoginConfig,
  useRequestLoginInterface,
} from "./useRequestLoginInterface";
import { DefaultServiceResponse } from "../../interfaces/DefaultServiceResponse";

/**
 * * Hook to handle logging a user in to the app
 */
export const useRequestLogin = <
  T extends LoginResponseInterface,
>(): useRequestLoginInterface<T> => {
  const { t } = useTranslate();
  const [Data, setData] = useState<T | null>(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  /**
   * * Hook into the request service
   */
  const { IsLoading, RequestError, sendPostRequest } = useRequestService<T>();

  /**
   * * SageID login route On successful post request with bearer token, navigate to the app
   * @param param0
   * @param tenant
   * @returns
   */
  const handleSageLogin = ({ success }: DefaultServiceResponse, tenant: string) => {
    if (!success) {
      return;
    }
    setIsRedirecting(true);
    deleteSessionStorageItem(SS_TEMP_ACCESS_TOKEN);
    const SfAIds = getSessionSfAIds();
    if (SfAIds) {
      const { cid } = SfAIds;
      // * if client id is present, then navigate to SFA Client Record
      if (cid) {
        return (window.location.href = generateCompleteURL(
          Redirects.sfa.client_record,
          { tenant, clientId: cid },
          {}
        ));
      }
    }
    // * Otherwise navigate to the app in general
    return (window.location.href = generateCompleteURL(Redirects.gp_app, { tenant }, {}));
  };

  /**
   * * Handle logging in via the SageId route for a given tenant
   */
  const postSageIdLogin = useCallback(
    (accessToken: string, tenant: string) => {
      if (!tenant) {
        toast.error(`${t("errors.wentWrong")}: ${t("errors.tenantNotSpecified")}`);
        deleteSessionStorageItem(SS_TEMP_ACCESS_TOKEN);
        return;
      }

      sendPostRequest(
        {
          url: generateCompleteURL(Endpoints.login.sageId, { tenant }, {}),
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        (data) => handleSageLogin(data as unknown as DefaultServiceResponse, tenant)
      );
    },
    [sendPostRequest, t]
  );

  /**
   * * Post login credentials
   * * Handle logging in via the legacy (non-SageID route) for a given tenant
   */
  const postLegacyLogin = useCallback(
    (config: postLegacyLoginConfig) => {
      const tenant = Cookies.getCookie(TENANT_COOKIE);

      if (!tenant) {
        toast.error(`${t("errors.wentWrong")}: ${t("errors.tenantNotSpecified")}`);
        deleteSessionStorageItem(SS_TEMP_ACCESS_TOKEN);
        return;
      }
      sendPostRequest(
        { url: generateCompleteURL(Endpoints.login.legacy, { tenant }, {}), data: config },
        (data) => setData(data)
      );
    },
    [sendPostRequest, t]
  );

  return {
    isRedirecting,
    Tokens: Data,
    IsLoading,
    RequestError,
    postLegacyLogin,
    postSageIdLogin,
  };
};
