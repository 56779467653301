/**
 * * Define the Auth token state
 */

import { Cookies } from "utilities/Cookies/Cookies";
import { getSessionStorageItem } from "utilities/SessionStorage/SessionStorage";
import { SS_TEMP_ACCESS_TOKEN } from "utilities/SessionStorage/storageKeys";

export const LOGIN_WITH_SAGE_NAME = "loginWithSage";
export const WP_LOGIN_COOKIE = "wordpress_logged_in";
export const COOKIE_MAX_AGE = 3600;

/**
 * * Auth state interface
 */
export interface AuthStateInterface {
  /**
   * * access (auth) token
   */
  readonly token: null | string;

  readonly expires_at: null | number;

  /**
   * * Is Authenticated (dependent on having auth_token)
   */
  readonly is_authenticated: boolean;
}

/**
 * * On initial start up, check if we already have an auth token stored on the client
 * * Note - this could easily be an invalid token, but that would require
 * * additional requests to check the token validity
 * * Instead, the user is force logged out when a request fails due to unauth'd
 */

let token = null;
let expires_at: number | null = null;
let isLoginWithSage: boolean;

try {
  // TODO: maybe remove
  // variable that prevents false positive authentication due to being shared with Sageid and traditional login
  isLoginWithSage = Cookies.getCookie(LOGIN_WITH_SAGE_NAME) === "true";

  const tempAccessToken = getSessionStorageItem(SS_TEMP_ACCESS_TOKEN);
  if (tempAccessToken) {
    token = decodeURIComponent(tempAccessToken);
  }
} catch (e) {
  isLoginWithSage = false;
  token = null;
  expires_at = null;
}

/**
 * * Define the initial auth state
 */
export const InitialAuthState: AuthStateInterface = {
  token: token && token !== "undefined" ? token : null,
  expires_at: expires_at ?? null,
  is_authenticated: isLoginWithSage ? false : !!(token && token !== "undefined"),
};
