import { AxiosResponse } from "axios";

/**
 * * Handle the axios response
 * @param {AxiosResponse<T, unknown>} response Response from the request
 * @param {(data: T) => void} callback callback handler for a successful response
 * @param {React.Dispatch<React.SetStateAction<string | null>} setRequestError Handle error response
 */
export const handleResponse = <T>(
  response: AxiosResponse<T, unknown>,
  callback: (data: T) => void,
  setRequestError: React.Dispatch<React.SetStateAction<string | null>>
) => {
  const { data } = response;
  let responseData: T | null = null;

  /**
   * * If response is a string format, then it needs to be parsed
   */
  if (typeof data === "string") {
    try {
      responseData = JSON.parse(data) as T;
    } catch (e) {
      if (typeof e === "string") {
        throw new Error(e);
      } else if (e instanceof Error) {
        throw new Error(e.message);
      }
    }
  } else {
    // * Assign data to responseData
    responseData = data;
  }

  if (responseData) {
    // will block any further code if there are errors
    handleResponseWithError(responseData as ResponseWithError);
    // * pass response.data to callback
    callback(responseData);
  } else {
    setRequestError("Request failed. Please try again.");
  }
};

interface ResponseWithError {
  readonly error?: string;
  readonly [key: string]: unknown;
}

const handleResponseWithError = <T extends ResponseWithError>({ error }: T) => {
  if (!error) {
    return;
  }
  throw new Error(error);
};
