import tokens from "@sage/design-tokens/js/base/es6";
import Box from "carbon-react/lib/components/box";
import Content from "carbon-react/lib/components/content";
import { PageTransition } from "components/Transitions/PageTransition";
import { AppLogo } from "components/UI/AppLogo";
import { PropsWithChildren } from "react";

const CustomBoxStyles = {
  border: `1px solid ${tokens.colorsUtilityMajor100}`,
};

export const PageCard = ({ children }: PropsWithChildren<unknown>) => (
  <PageTransition>
    <Box display="flex" width={1} minHeight="100vh" alignItems="center" justifyContent="center">
      <Box
        width="100%"
        maxWidth="400px"
        bg="--colorsUtilityYang100"
        borderRadius="borderRadius100"
        style={CustomBoxStyles}
      >
        <Box px={4} pt={0} pb={3} boxSizing="border-box">
          <Content align="left">{children}</Content>
        </Box>
      </Box>
    </Box>
  </PageTransition>
);

export const PageCardWithAppLogo = ({ children }: PropsWithChildren<unknown>) => (
  <PageCard>
    <AppLogo />
    {children}
  </PageCard>
);
