const tidParamKey = "tid";
const tidSessionKey = "sfa-tid";
const cidParamKey = "cid";
const cidSessionKey = "sfa-cid";

export const storeSessionSfAIds = () => {
  const params = new URLSearchParams(window.location.search);
  const tid = params.get(tidParamKey);
  const cid = params.get(cidParamKey);

  // * if tenant id, store it
  tid && sessionStorage.setItem(tidSessionKey, tid);
  // * if tenant id and client id, store cid. If not tid, don't bother with cid
  tid && cid && sessionStorage.setItem(cidSessionKey, cid);
};

export const getSessionSfAIds = (): {
  tid: string;
  cid: string | null;
} | null => {
  const tid = sessionStorage.getItem(tidSessionKey);
  const cid = sessionStorage.getItem(cidSessionKey);

  if (!tid) return null;

  return {
    tid: tid,
    cid: cid,
  };
};
