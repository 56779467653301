import { PageCard } from "components/PageCard/PageCard";
import { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Routes as PageRoutes } from "./routes";

const ProtectedRoute = lazy(() => import("containers/Login/Login"));
const DisclaimerComponent = lazy(() => import("containers/Home/Home"));
const ResetPasswordComponent = lazy(() => import("containers/ResetPassword/ResetPassword"));
const InviteComponent = lazy(() => import("containers/Invite/Invite"));
const InviteCallbackComponent = lazy(() => import("containers/Invite/InviteCallback"));
const SageIdCallbackComponent = lazy(() => import("containers/SageIDCallback/SageIDCallback"));
const SageIdCallbackNewUserComponent = lazy(() => import("containers/SageIDCallback/NewUser"));
const LogoutComponent = lazy(() => import("containers/Logout/Logout"));
const NotFoundComponent = lazy(() => import("containers/NotFound/NotFound"));

const Router = () => (
  <Routes>
    <Route path={PageRoutes.Root} element={<ProtectedRoute />}>
      <Route path={PageRoutes.Root} element={<DisclaimerComponent />} />
    </Route>

    <Route path={PageRoutes.SageIdCallback} element={<PageCardOutlet />}>
      <Route path={PageRoutes.SageIdCallback} element={<SageIdCallbackComponent />}>
        <Route path={PageRoutes.SageIdCallbackNew} element={<SageIdCallbackNewUserComponent />} />
      </Route>
    </Route>

    <Route path={PageRoutes.Invite} element={<InviteComponent />} />
    <Route path={PageRoutes.InviteCallback} element={<InviteCallbackComponent />} />

    <Route path={PageRoutes.ResetPassword} element={<ResetPasswordComponent />} />

    <Route path={PageRoutes.Logout} element={<LogoutComponent />} />

    <Route path="*" element={<NotFoundComponent />} />
  </Routes>
);

const PageCardOutlet = () => (
  <PageCard>
    <Outlet />
  </PageCard>
);

export default Router;
