import { improvedEncodeURIComponent } from "utilities/improvedEncodeURIComponent/improvedEncodeURIComponent";
import { isLocalEnv } from "utilities/utils";

export class Cookies {
  /**
   * * Set a cookie
   * @param {string} name Name of the cookie to set
   * @param {string} value Value of the cookie being set
   * @param {number} maxAge How long in the cookie will live for
   */
  static setCookie(name: string, value: string | number, maxAge: number) {
    value = improvedEncodeURIComponent(value);
    document.cookie = `${name}=${value}; max-age=${maxAge}; path=/; domain=${window.location.hostname}`;
  }

  /**
   * * Set a session cookie
   * @param {string} name Name of the cookie to set
   * @param {string} value Value of the cookie being set
   * @param {boolean} httpOnly httpOnly flag to control the cookie visibility
   *
   */
  static setSessionCookie(name: string, value: string, httpOnly = false) {
    value = improvedEncodeURIComponent(value);

    document.cookie = `${name}=${value}; ${isLocalEnv ? "" : "SameSite=Strict; Secure;"} ${
      httpOnly ? "HttpOnly;" : ""
    } domain=${window.location.hostname}; path=/;`;
  }

  /**
   *
   * @param {string} name Cookie name to find
   * @returns {any} value of the found cookie or empty string if not
   */
  static getCookie(name: string) {
    const searchCookie: string | undefined = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`));

    if (searchCookie) {
      return searchCookie.split("=")[1];
    }

    return undefined;
  }

  /**
   * * Clear a named cookie
   * @param {string} name
   */
  static clearCookie(name: string) {
    document.cookie = `${name}=; max-age=0; expires=0; path=/; domain=${window.location.hostname}`;
  }
}
