import { useContext } from "react";
import LoginUserContext, { LoginUserContextInterface } from "./LoginUserContext";

const useLoginUser = () => {
  const context = useContext<LoginUserContextInterface>(LoginUserContext);

  // * Add some error logging
  if (context === undefined) {
    console.error(
      "[useLogin] Context not provided. Ensure your component is a child of <ThemeStoreProvider />"
    );
  }

  return context;
};

export default useLoginUser;
