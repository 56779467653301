import { useStateWithCallback } from "hooks/useStateWithCallback/useStateWithCallback";
import { Identifiable } from "interfaces/Identifiable";

/**
 *
 * @param {ReadonlyArray<T>} resArray resource array to update
 * @param {T | ReadonlyArray<T>} update resource/s to update / insert in array
 * @returns {ReadonlyArray<T>} updated array
 */
const replaceResource = <T extends Identifiable>(
  resArray: ReadonlyArray<T>,
  update: T | ReadonlyArray<T>
): ReadonlyArray<T> => {
  // * Provided multiple updates
  if (Array.isArray(update)) {
    const newResources = (update as ReadonlyArray<T>).filter(
      (u) => resArray.find((r) => r.id === u.id) === undefined
    );
    return resArray
      .map((res) => (update as ReadonlyArray<T>).find(({ id }) => id === res.id) || res)
      .concat(...newResources);
  } else {
    // * Provided single update
    const u = update as T; // for type sanity
    // * Does id already exist?
    if (resArray.findIndex((r) => r.id === u.id) > -1) {
      // * Map and replace
      return resArray.map((res) => (res.id === u.id ? u : res));
    } else {
      // * Map and append
      return resArray.map((res) => res).concat(update);
    }
  }
};

const createResourceArray = <T extends Identifiable>(
  resource: T | ReadonlyArray<T>
): ReadonlyArray<T> => {
  if (Array.isArray(resource)) {
    return (resource as ReadonlyArray<T>).map((res) => ({ ...res, id: res.id.toString() }));
  }
  return [resource as T];
};

type useResourceProps<T> = {
  initialData: ReadonlyArray<T> | T;
};

/**
 * * Use a managed array resource for storing data of a given Type in an Array
 * * Provides handlers for setting, updating and deleting
 * * Accepts an optional initialData:ReadonlyArray<T> to begin with
 */
export const useResource = <T extends Identifiable>(props?: useResourceProps<T>) => {
  const [resource, setResource] = useStateWithCallback<ReadonlyArray<T>>(
    props && props.initialData ? createResourceArray(props.initialData) : []
  );

  /**
   * * Create a resource array using immer
   * * Also converts all ids to strings to avoid need for typecasting
   * @param {T |ReadonlyArray<T>} res Seed array to create resource from
   * @param {(resource:ReadonlyArray<T>) => void} onUpdate Optional callback function when resource is updated - receives updated resource
   */
  const createResource = (res: T | ReadonlyArray<T>, onUpdate?: (res: ReadonlyArray<T>) => void) =>
    setResource(() => createResourceArray(res), onUpdate);

  /**
   * * Update a typed resource array with a single item of T or array of T.
   * * Overwrites any resource by matching ids, or adds to end of the array if no match
   * @param {T extends Identifiable |ReadonlyArray<T extends Identifiable>} update The typed update/s
   * @param {(resource:ReadonlyArray<T>) => void} onUpdate Optional callback function when resource is updated - receives updated resource
   */
  const updateResource = (
    update: T | ReadonlyArray<T>,
    onUpdate?: (resource: ReadonlyArray<T>) => void
  ) => setResource((prev) => replaceResource(prev, update), onUpdate);

  /**
   * * Delete a resource by id from a typed resource array
   * @param {T["id"] | ReadonlyArray<T["id"]} ids The ids of the resource(s) to remove
   * @param {(resource:ReadonlyArray<T>) => void} onUpdate Optional callback function when resource is updated - receives updated resource
   */
  const deleteResource = (
    ids: T["id"] | ReadonlyArray<T["id"]>,
    onUpdate?: (resource: ReadonlyArray<T>) => void
  ) => {
    setResource((prev) => {
      if (Array.isArray(ids)) {
        return prev.filter(({ id }) => !(ids as ReadonlyArray<T["id"]>).includes(id.toString()));
      }
      return prev.filter(({ id }) => id !== (ids as T["id"]));
    }, onUpdate);
  };

  return {
    resource,
    createResource,
    updateResource,
    deleteResource,
  };
};
